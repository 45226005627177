import React, { useEffect } from "react";
import moon from "./moon.png";
import home from "./home.png";
import social from "./social.png";
import library from "./library.png";
import arrow from "./arrow1.png";
import "./App.css";
import ReactPixel, {fbq} from 'react-facebook-pixel';


declare global {
  interface Window {
    gradient: any;
  }
}

function App() {
  useEffect(() => {
    const gradient1 = new window.gradient();
    const gradient2 = new window.gradient();
    const gradient3 = new window.gradient();
    const gradient4 = new window.gradient();
    gradient1.initGradient("#gradient1-canvas");
    gradient2.initGradient("#gradient2-canvas");
    gradient3.initGradient("#gradient3-canvas");
    gradient4.initGradient("#gradient4-canvas");
  });

  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init('739941200205516', undefined, options);
    ReactPixel.pageView(); 
  }, [])

  return (
    <div className="page">
      <div className="Header">
        <div className="HeaderContent">
          <div className="logo">
            <a href="https://airtable.com/shrccWRAbyG3hBZ3c">
              <img className="moon" src={moon} alt="screenshot" />
            </a>
            <h1 className="HeaderTitleMain">alto.fm</h1>
          </div>
          <h1 className="HeaderDescriptionMain">
            Social music streaming app in private beta.
          </h1>
          <button
            className="HeaderLinkMain"
            onClick={() => {
              window.location.replace("https://airtable.com/shrccWRAbyG3hBZ3c");
            }}
          >
            <div className="HeaderGetAccess">
              <h1 className="HeaderDescriptionMain HeaderAction">
                Get early access
              </h1>
              <img className="arrow" src={arrow} alt="get access" />
            </div>
          </button>
        </div>
      </div>
      <div className="Preview">
        <canvas id="gradient1-canvas"></canvas>
        <img className="Image" src={home} alt="screenshot" />
      </div>
      <div className="Section1">
        <div className="HeaderContent">
          <h1 className="HeaderTitle">More than just a music platform</h1>
          <h1 className="HeaderDescription">
            Alto.fm is a community that allows people to connect through music.
            Whether you share favourite tracks with followers or discover new
            artists through others, Alto.fm is empowering music curators and
            keeping your library fresh.
          </h1>
        </div>
      </div>
      <div className="Preview">
        <canvas id="gradient2-canvas"></canvas>
        <img className="Image" src={social} alt="screenshot" />
      </div>
      <div className="Section2">
        <div className="HeaderContent">
          <h1 className="HeaderTitle">Listen together in real-time</h1>
          <h1 className="HeaderDescription">
            Tune into whatever your friends are jamming to, or host listening
            parties yourself. With real-time shared listening, Alto.fm is
            handing you the aux.
          </h1>
        </div>
      </div>
      <div className="Preview">
        <canvas id="gradient3-canvas"></canvas>
        <img className="Image" src={library} alt="screenshot" />
      </div>
      <div className="Section3">
        <div className="HeaderContent">
          <h1 className="HeaderTitle">Keep your Apple Music library</h1>
          <h1 className="HeaderDescription">
            No new monthly subscription fee. Alto.fm connects seamlessly to your
            Apple Music account allowing you to enjoy the same songs and
            playlists you enjoy across both platforms.
          </h1>
        </div>
      </div>
      <div className="signup">
        <canvas id="gradient4-canvas"></canvas>
        <div className="linkContainer">
          <button className="link" onClick={() => {
            window.location.replace("https://airtable.com/shrccWRAbyG3hBZ3c");
          }}>
            Get Early Access
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
